import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getSignUpToken } from '../Services/signUpReducer';
import { useAppSelector } from '../Store/hooks';
import { getCurrentUser } from '../Services/userReducer';
import { useGetfqasQuery } from '../Services/fqasApi';
import { useGetHelpDeskVideoURLQuery } from '../Services/envApi';
import Header from './Header';
import Footer from './Footer';
import Icon from '../Components/base/icon/icon';
import CloseIcon from '../Assets/icons/beeMG-icons-close.svg';
import beemg_arrow from '../Assets/icons/beemg-arrow.svg';

type FAQItem = {
  id: string;
  question: string;
  answer: string;
  faqId: string;
  faqOrder: number;
};
interface IFaq {
  id: string;
  question: string;
  answer: string;
  faqId: string;
}
interface IBookmark {
  id: string;
  question: string;
  answer: string;
  faqId: string;
  faqOrder: number;
}

function HelpDesk() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useAppSelector(getSignUpToken);
  const currentUser = useAppSelector(getCurrentUser);
  const { data: faqs, isLoading: faqsLoading } = useGetfqasQuery();

  // State for selected FAQ
  const [selectedFAQ, setSelectedFAQ] = useState<FAQItem | null>(null);
  const [bookmarks, setBookmarks] = useState<IBookmark[]>([]);
  const [showBookmarks, setShowBookmarks] = useState<boolean>(false);

  // UseMemo for transformed FAQs
  const transformedData = useMemo(() => {
    return {
      rows:
        faqs?.map((faq) => ({
          id: faq.id,
          question: faq.question,
          answer: faq.answer || 'No faqAnswer available',
          faqId: faq.faqId,
          faqOrder: faq.faqOrder,
        })) || [],
    };
  }, [faqs]);


  // Load bookmarks from localStorage
  useEffect(() => {
    const storedBookmarks = localStorage.getItem('bookmarks');
    if (storedBookmarks) setBookmarks(JSON.parse(storedBookmarks));
  }, []);

  // Add Bookmark
  const handleAddBookmark = () => {
    if (selectedFAQ) {
      const isAlreadyBookmarked = bookmarks.some((bm) => bm.id === selectedFAQ.id);
      if (!isAlreadyBookmarked) {
        const newBookmarks = [...bookmarks, selectedFAQ];
        setBookmarks(newBookmarks);
        localStorage.setItem('bookmarks', JSON.stringify(newBookmarks));
        alert('Bookmark added!');
      } else {
        alert('This FAQ is already bookmarked.');
      }
    }
  };

  // Toggle View Bookmarks
  const handleViewBookmarks = () => {
    setShowBookmarks(!showBookmarks); // Toggle bookmarks visibility
  };

  // Show/Hide bookmarks panel
  const handleToggleBookmarks = () => setShowBookmarks(!showBookmarks);

  const handleFAQClick = (faq: FAQItem) => {
    setSelectedFAQ(faq); // Immediate state update
    // Update the URL with FAQ ID
    const newUrl = `${location.pathname}?faq=${faq.id}`;
    window.history.pushState({}, '', newUrl);
  };

  const { data: HelpDeskVideoURL } = useGetHelpDeskVideoURLQuery();
  const videoEntries = HelpDeskVideoURL ? Object.entries(HelpDeskVideoURL) : [];
  const videofaqQuestionMapping: { [key: string]: string } = {
    beemg_intro1: 'BeeMG Intro',
    beemg_creator1: 'BeeMG Creator',
    beemg_collaborator1: 'BeeMG Collaborator',
    beemg_engagement1: 'BeeMG Engagement',
    beemg_monetize1: 'BeeMG Monetize',
  };

  return (
    <div className="flex flex-col">
      {!currentUser && <Header />}
      <div className={`flex-grow overflow-y-auto ${currentUser ? 'h-full' : ''}`}>
        <div className="w-full flex flex-col items-center overflow-y-auto justify-center">
          <div className="mb-8 gap-2 w-full lg:w-9/12 flex place-items-center">
            <div className="w-full mt-10">
              <div className="flex items-center justify-between mt-2 mb-8">
                {/* Left-end View Bookmarks button */}
                {!showBookmarks && (
                  <button
                    onClick={handleToggleBookmarks} // Functionality for toggling bookmarks
                    className="px-4 py-2 bg-BeeMG-yellow text-white rounded text-sm md:text-base hover:bg-BeeMG-orange"
                  >
                    {showBookmarks ? 'Close Bookmarks' : 'Bookmarked topics'}
                  </button>
                )}
                {/* Centered Title */}
                <div className="text-xl md:text-2xl font-medium text-center flex-1">
                  <div className="px-8 py-2 rounded">{t('BeeMG HelpDesk')}</div>
                </div>
              </div>

              {showBookmarks && (
                <div
                  className="fixed top-0 left-0 w-95 bg-white shadow-lg p-6 z-[9999] overflow-y-auto shadow-[2px_0px_5px_rgba(0,0,0,0.2)] h-screen"
                >
                  {/* Header for bookmarks */}
                  <div className="flex justify-between items-center mb-8 px-3">
                    <h2 className="text-lg font-bold">Bookmarked Topics</h2>
                    <button
                      onClick={handleToggleBookmarks}
                      className="text-gray-500 hover:text-black mb-1"
                    >
                      <img src={CloseIcon} alt="close" width={20} height={20} />
                    </button>
                  </div>

                  {/* List of bookmarks */}
                  <ul>
                    {bookmarks.map((bookmark) => (
                      <li
                        key={bookmark.id}
                        className="flex justify-between items-center p-3 mb-2 border-b cursor-pointer hover:bg-gray-100 rounded"
                        onClick={() => handleFAQClick(bookmark)}
                      >
                        <span className="text-gray-800 font-medium">{bookmark.question}</span>
                        <span className="text-gray-500 px-3"><img src={beemg_arrow} alt="Beemg Logo" width={10} height={10}/></span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {/* Video Section */}

              <div className="flex justify-between items-center gap-1 my-10">
                {videoEntries.length > 0 ? (
                  videoEntries.map(([key, videoUrl], index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center w-1/4 p-2 rounded-md"
                    >
                      <div className="bg-gray-300 w-full h-40 flex justify-center items-center rounded-md">
                        <video src={videoUrl as string}  className="w-full h-full object-cover" controls>
                          <source src={videoUrl as string} type="video/mp4" />
                        </video>
                      </div>
                      <p className="mt-2 text-center text-sm">  {videofaqQuestionMapping[key + '1'] || `Video faqQuestion ${index + 1}`}</p>
                    </div>
                  ))
                ) : (
                  <p>No videos available</p>
                )}
              </div>

              <hr className="mb-4" />

              <div className="flex">
                {/* Left Column: FAQ List */}
                <div className="w-1/2 p-4 max-h-60 overflow-y-auto">
                  <h2 className="text-lg font-medium mb-3">
                    Hi {currentUser ? currentUser.firstName : ''}, what are you looking for?
                  </h2>
                  {transformedData.rows.map((item, index) => (
                    <div
                      key={index}
                      className={`cursor-pointer p-4 mb-4 rounded-lg ${selectedFAQ?.question === item.question
                        ? 'bg-BeeMG-yellow'
                        : 'bg-gray-100 hover:bg-gray-200'
                        }`}
                      onClick={() => handleFAQClick(item)} // Fast click handling
                    >
                      <div className="flex items-center justify-between">
                        <span className="text-lg font-semibold">{item.question}</span>
                        <img src={beemg_arrow} alt="Beemg Logo" width={12} height={12}  />
                      </div>
                    </div>
                  ))}
                </div>

                {/* Right Column: FAQ Details */}
                <div className="w-1/2 p-4 max-h-60 overflow-y-auto">
                  {selectedFAQ ? (
                    <div>
                      <h2 className="text-xl font-bold">{selectedFAQ.question}</h2>
                      <p
                        className="mt-4"
                        dangerouslySetInnerHTML={{
                          __html: selectedFAQ.answer,
                        }}
                      ></p>
                      <div className="mt-4 text-sm font-semibold">Is it helpful?</div>
                      <div className="grid grid-cols-3 gap-6">
                        <div className="mt-4 flex gap-2">
                          <button className="bg-gray-300 text-black py-1 px-2 rounded">Yes</button>
                          <button className="bg-gray-300 text-black py-1 px-2 rounded">No</button>
                        </div>
                        <div className="mt-4 flex gap-1 justify-end">
                          <Icon
                            icon="RIGHT"
                            size="medium"
                            height="large"
                            stroke="#535353"

                          ></Icon>
                          <button
                            onClick={handleAddBookmark}
                          >
                            Add Bookmark
                          </button>
                        </div>
                        <div className="mt-4 flex gap-1 justify-end">
                          <Icon icon="RIGHT" size="medium" height="large" stroke="#535353" />
                          <button className="text-black">View Related</button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-gray-500 text-center">Select a topic to view here</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!currentUser && <Footer />}
    </div>
  );
}

export default HelpDesk;
