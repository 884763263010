/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React, { useState } from 'react'
import {
  FocusToggle,
  isTrackReference,
  ParticipantContextIfNeeded,
  TrackRefContext,
  TrackReferenceOrPlaceholder,
  useEnsureTrackRef,
  useFeatureContext,
  useMaybeTrackRefContext,
  VideoTrack,
} from '@livekit/components-react'
import { LocalParticipant, Track } from 'livekit-client'
import { CustomLiveShoppingPlaceholder } from './CustomLiveShoppingPlaceholder'
import ParticipantCount from 'livekit/header/ParticipantCount'
import { useCustomRoomContext } from '../CustomRoomContext'
import { useControlBarAccessHooks, useUpdateBidAmount } from 'livekit/livekitHooks'
import { faShare, faHeart as filledHeart, faHeart as outlineHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TrackRefContextIfNeeded(
  props: React.PropsWithChildren<{
    trackRef?: TrackReferenceOrPlaceholder
  }>
) {
  const hasContext = !!useMaybeTrackRefContext()
  return props.trackRef && !hasContext ? (
    <TrackRefContext.Provider value={props.trackRef}>{props.children}</TrackRefContext.Provider>
  ) : (
    <>{props.children}</>
  )
}

export default function LiveShopping({ trackRef, children }: any) {
  const trackReference = useEnsureTrackRef(trackRef)
  const autoManageSubscription = useFeatureContext()?.autoSubscription

  function SharedControlButtons() {
    const { bidAmount, setBidAmount, lastSentBid, setLastSentBid } = useCustomRoomContext()
    const [customPrice, setCustomPrice] = useState('')
    const [isCustomInputVisible, setIsCustomInputVisible] = useState(false)
    const { isHostOrCoHostAvailableRoom } = useControlBarAccessHooks()
    const { updateBidAmount } = useUpdateBidAmount()
    const [liked, setLiked] = useState(false)

    const toggleLike = () => {
      setLiked(!liked)
    }

    const updateBidAmountForAll = (newBidAmount: number) => {
      setBidAmount(newBidAmount)
      setLastSentBid(newBidAmount)
      updateBidAmount({ type: 'BID_UPDATE', bidAmount: newBidAmount })
    }

    const increaseLocalBid = () => {
      setBidAmount((prev: number) => prev + 10)
    }

    const handleCustomPriceSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && customPrice) {
        const price = parseInt(customPrice, 10)
        if (isNaN(price)) return
        if (price < (lastSentBid ?? 0)) {
          alert(`Bid amount cannot be less than the ongoing bid amount of ${lastSentBid}`)
          return
        }
        setBidAmount(price)
        setCustomPrice('')
        setIsCustomInputVisible(false)
      }
    }

    return (
      <>
        {!isHostOrCoHostAvailableRoom ? (
          <div className="absolute inset-0 flex flex-col justify-between items-center z-20">
            {/* Like and Share Buttons */}
            <div className="absolute top-1/2 right-2 transform -translate-y-1/2 flex flex-col gap-3 items-center">
              <button
                className={`flex items-center justify-center rounded-full border ${
                  liked ? 'bg-red-100' : 'bg-gray-300'
                } w-10 h-10`}
                aria-label={liked ? 'Unlike' : 'Like'}
                onClick={toggleLike}
              >
                <FontAwesomeIcon
                  icon={liked ? filledHeart : outlineHeart}
                  className={liked ? 'text-red-500' : 'text-gray-500'}
                />
              </button>
              <button
                className="flex items-center justify-center rounded-full border bg-gray-300 w-10 h-10"
                aria-label="Share"
              >
                <FontAwesomeIcon icon={faShare} />
              </button>
            </div>

            {/* Custom, Bid, and + Buttons */}
            <div className="absolute bottom-10 flex flex-wrap items-center justify-center gap-4">
              <button
                className="text-center justify-center rounded-full border bg-gray-400 w-20 h-8 flex items-center"
                onClick={() => setIsCustomInputVisible(!isCustomInputVisible)}
              >
                Custom
              </button>
              {isCustomInputVisible && (
                <div className="relative right-5">
                  <input
                    type="number"
                    className="border px-2 py-1 rounded absolute -top-12 sm:-top-14"
                    value={customPrice}
                    onChange={(e) => setCustomPrice(e.target.value)}
                    onKeyDown={handleCustomPriceSubmit}
                    placeholder="Enter price"
                  />
                  {parseInt(customPrice, 10) <= (lastSentBid ?? 0) && customPrice && (
                    <div className="text-red-500 text-xs absolute -top-16 sm:-top-24 whitespace-nowrap">
                      Bid amount cannot be less than {lastSentBid}
                    </div>
                  )}
                </div>
              )}
              <button
                className={`text-center justify-center rounded-md px-4 py-1 ${
                  lastSentBid === bidAmount ? 'bg-red-400' : 'bg-blue-400'
                }`}
                onClick={() => updateBidAmountForAll(bidAmount)}
                disabled={lastSentBid === bidAmount}
              >
                Bid {bidAmount}
              </button>

              <button
                className="text-center justify-center rounded-full border bg-green-300 w-8 h-8 flex items-center"
                onClick={increaseLocalBid}
              >
                +
              </button>
            </div>
          </div>
        ) : (
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="text-center justify-center rounded-md border bg-blue-400 px-4 py-1 absolute bottom-8">
              Bid {bidAmount}
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <div>
        <TrackRefContextIfNeeded trackRef={trackReference}>
          <ParticipantContextIfNeeded participant={trackReference.participant}>
            {children ?? (
              <>
                {isTrackReference(trackReference) &&
                  trackReference.publication?.kind === 'video' &&
                  (trackReference.source === Track.Source.Camera &&
                  trackReference.publication?.isMuted === false &&
                  trackReference.participant instanceof LocalParticipant ? (
                    <div className="transform scale-x-[-1] h-full w-full relative">
                      {/* Participant Count at the top-right corner */}
                      <div className="absolute top-2 left-2 bg-gray-600 text-white text-sm px-2 py-1 rounded z-10 transform scale-x-[-1]">
                        <ParticipantCount />
                      </div>
                      {/* Video */}
                      <VideoTrack
                        trackRef={trackReference}
                        manageSubscription={autoManageSubscription}
                        className="relative aspect-[9/11] rounded-md h-full w-auto object-cover transform scale-x-[-1]"
                        onError={(error) => console.error('Video track error:', error)}
                      />
                      <div className="transform scale-x-[-1]">
                        <SharedControlButtons />
                      </div>
                    </div>
                  ) : (
                    ((trackReference.source === Track.Source.Camera && trackReference.publication?.isMuted === false) ||
                      trackReference.source === Track.Source.ScreenShare) && (
                      <div className="relative">
                        {/* Participant Count at the top-right corner */}
                        <div className="absolute top-2 right-2 bg-gray-600 text-white text-sm px-2 py-1 rounded z-10">
                          <ParticipantCount />
                        </div>
                        <VideoTrack
                          trackRef={trackReference}
                          manageSubscription={autoManageSubscription}
                          className="relative aspect-[9/11] rounded-md h-full w-auto object-cover"
                          onError={(error) => console.error('Video track error:', error)}
                        />
                        <SharedControlButtons />
                      </div>
                    )
                  ))}

                {((trackReference.publication?.kind === 'video' &&
                  trackReference.source === Track.Source.Camera &&
                  trackReference.publication?.isMuted === true) ||
                  trackReference?.publication === undefined) && (
                  <>
                    <div className="relative h-full w-full">
                      <CustomLiveShoppingPlaceholder height={40} width={30} />
                      <SharedControlButtons />
                    </div>
                  </>
                )}
              </>
            )}
            <FocusToggle trackRef={trackReference} />
          </ParticipantContextIfNeeded>
        </TrackRefContextIfNeeded>
      </div>
    </>
  )
}
