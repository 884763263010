/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { GridLayoutProps, TrackLoop } from '@livekit/components-react'
import * as React from 'react'
import { useCustomRoomContext } from './CustomRoomContext'
import {
  useGetHostAndCoHost,
  useReceiveStagedParticipantsList,
  useScreenDimensions,
  useSendStagedParticipantsList,
} from '../livekitHooks'
import { useCustomGridContext } from './CustomGridContext'
import { Track } from 'livekit-client'

/**
 * CustomGridLayout component displays the nested participants in a grid where every participant has the same size.
 *
 * @param {GridLayoutProps} props - The props object containing tracks and additional props.
 * @return {JSX.Element} The rendered GridLayout component.
 */

export function CustomGridLayout({ tracks, ...props }: GridLayoutProps) {
  const { gridWrapperRef } = useScreenDimensions(tracks)
  const {
    gridDimensions,
    // stagedParticipants,
    // handRaisedParticipants,
    // unpinnedLayoutPositions,
    viewType,
    setAllTracksSids,
    setAllTracks,
  } = useCustomRoomContext()
  const { hostAndCoHosts } = useGetHostAndCoHost()
  const { clearPinnedTrackOrParticipants, setPinnedParticipants } = useCustomGridContext()

  useSendStagedParticipantsList()
  useReceiveStagedParticipantsList()

  // const otherTracksForHost = tracks.filter(
  //   (track) =>
  //     stagedParticipants.includes(track.participant.sid) || handRaisedParticipants.includes(track.participant.sid)
  // )

  // const count = otherTracksForHost.length + unpinnedLayoutPositions.length

  // Host or Co-host screen share - track ids
  // const hostScreenSharedTracks = tracks
  //   .filter(
  //     (track) =>
  //       track.source === Track.Source.ScreenShare && hostAndCoHosts.some((host) => host === track.participant.sid)
  //   )
  //   // .map((track) => track.participant.sid + '-' + track?.source)
  //   .map((track) => track.publication?.trackSid ?? track.participant.sid) // Pinned participant

  // console.log('hostScreenSharedTracks', hostScreenSharedTracks)

  const screenSharedTracks = tracks
    .filter((track) => track.source === Track.Source.ScreenShare)
    .map((track) => track.publication?.trackSid ?? track.participant.sid)

  const othersRef = React.useRef<HTMLDivElement>(null)

  // React.useEffect(() => {
  //   if (gridWrapperRef.current) {
  //     gridWrapperRef.current.style.minWidth = `${count * 17 * 7}px`
  //   }
  // }, [count])

  React.useEffect(() => {
    if (othersRef.current && gridDimensions) {
      othersRef.current.style.bottom = `${gridDimensions.height / 8}px`
      othersRef.current.style.right = `${gridDimensions.width / 25}px`
    }
  }, [gridDimensions])

  React.useEffect(() => {
    if (viewType === 'grid') {
      clearPinnedTrackOrParticipants()
    } else if (viewType === 'hostHighlighted') {
      const hostAndCoHostTrackSids: string[] = []

      for (let i = 0; i < hostAndCoHosts.length; i++) {
        const hostTracks = tracks.filter((track) => track.participant.sid === hostAndCoHosts[i])
        if (!hostTracks) {
          hostAndCoHostTrackSids.push(hostAndCoHosts[i]) // Participant SID
        } else {
          hostAndCoHostTrackSids.push(
            ...hostTracks.map((track) => track.publication?.trackSid ?? track.participant.sid)
          )
        }
      }
      setPinnedParticipants(hostAndCoHostTrackSids)
      if (screenSharedTracks.length > 0) {
        setPinnedParticipants(screenSharedTracks)
      }
    }
  }, [viewType, hostAndCoHosts.length, screenSharedTracks.length, tracks])

  React.useEffect(() => {
    setAllTracksSids(tracks.map((track) => track.publication?.trackSid ?? track.participant.sid))
    setAllTracks(tracks)
  }, [tracks])

  return (
    <>
      <div className={`flex flex-row w-full h-full overflow-x-scroll overflow-hidden scrollbar-hide`}>
        <div ref={gridWrapperRef} className="w-[100vw] responsive-grid relative">
          <TrackLoop tracks={tracks}>{props.children}</TrackLoop>
        </div>
        {/* <div className='bg-blue-500'>
          {unpinnedLayoutPositions.length > 3 && (
            <span
              ref={othersRef}
              className="absolute bg-[#FFC200] text-black py-1 px-2 rounded font-bold text-xs z-1000"
            >
              {unpinnedLayoutPositions.length - 3} others
            </span>
          )}
        </div> */}
      </div>
    </>
  )
}
