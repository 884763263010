/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Auth } from 'aws-amplify'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../Components'
import Icon from '../../Components/base/icon/icon'
import Loading from '../../Components/base/loading/loading'
import { PhoneNumberField } from '../../Components/base/phone-number/PhoneNumberField'
import { toastError, toastSuccess } from '../../Components/toast'
import { usePhoneVerificationTimerHook, usePhoneVerifyHook, useSignedInPhoneVerifyHook } from '../../Hooks/PhoneVerify'
import { changeCognitoModelObject, getCognitoObject } from '../../Services/signUpReducer'
import { useUpdateCognitoPhoneNumberMutation } from '../../Services/userApi'
import { getCurrentUser, getCurrentUserDetail } from '../../Services/userReducer'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import { phoneNumberValidationSchema } from '../../Utils/validation'
import { User, Verified } from '../../models/user.model'
import { UserDetail } from '../../models/userDetail.model'
import InitialPage from '../InitialPage'
import OTPInput from '../../Components/otpInput'
import BeemgPrimaryLogo from '../../Assets/NewIcons/beemg-logos-primary.svg'

// const OtpInput = lazy(() => import("react-otp-input"));
interface Initial {
  otp: string,
  phoneNumber?: string,
}

/**
 * PhoneVerify
 *
 * Phone verification page for both signed in and signed out users.
 *
 * Signed out users:
 * - Enters phone number and receives a verification code via SMS.
 * - Enters the verification code and submits it to verify the phone number.
 * - If the verification code is correct, the user is redirected to the next page.
 * - If the verification code is incorrect, the user is shown an error message.
 *
 * Signed in users:
 * - Enters phone number and receives a verification code via SMS.
 * - Enters the verification code and submits it to verify the phone number.
 * - If the verification code is correct, the user is redirected to the next page.
 * - If the verification code is incorrect, the user is shown an error message.
 *
 * @returns {JSX.Element} The PhoneVerify component.
 */
function PhoneVerify() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [serverError, setServerError] = useState(false);
  const [changePhoneNumber, setChangePhoneNumber] = useState(false);

  const { register, handleSubmit, control, getValues, setValue, watch, formState: { errors, isValid } } = useForm<Initial>({
    mode: "onChange", resolver: yupResolver(phoneNumberValidationSchema)
  });
  const {
    resendCode,
    phoneError,
    setPhoneError,
    onSubmit,
    nextExpiryDateTime,
    t,
    showToastMessage,
    setShowToastMessage
  } = usePhoneVerifyHook();
  const { isExpired } = usePhoneVerificationTimerHook(nextExpiryDateTime)

  const cognitoModel = useSelector(getCognitoObject) ?? JSON.parse(localStorage.getItem('SIGNIN_HELPER_COGNITOMODEL') as any);
  const currentUserDetail = useAppSelector(getCurrentUserDetail)
  const currentUser: User = useSelector(getCurrentUser)
  const [updatePhoneNumber] = useUpdateCognitoPhoneNumberMutation()

  const [otp, setOtp] = useState('')
  const [otpCount, setOtpCount] = useState<number>(0);

  if (phoneError && !serverError) {
    setServerError(true);
  }

  if (showToastMessage) {
    toastSuccess(t("otpResentSuccessfully"));
    setShowToastMessage(false);
  }

  /**
   * Sends an OTP to the given phone number.
   *
   * If the phone number is different from the one stored in the state, it will update the phone number in the state and resend a verification code.
   * If the phone number is the same as the one stored in the state, it will not send an OTP.
   *
   * @param {string | undefined} phoneNumber - The phone number to send the OTP to.
   */
  const sendOTP = async (phoneNumber: string | undefined) => {

    if (phoneNumber === undefined || phoneNumber === cognitoModel?.phone_number) {
      return
    } else {

      setValue('otp', '')
      let cognitoModelCopy = { ...cognitoModel } as UserDetail;
      cognitoModelCopy.phone_number = phoneNumber.includes('+') ? phoneNumber : '+' + phoneNumber;
      // SSO Signup Check

      updatePhoneNumber({ email: cognitoModelCopy?.email, phoneNumber: cognitoModelCopy?.phone_number }).then((res) => {

        localStorage.setItem('SIGNIN_HELPER_COGNITOMODEL', JSON.stringify(cognitoModelCopy));
        dispatch(changeCognitoModelObject(cognitoModelCopy));

        Auth.resendSignUp(cognitoModel.email).then(() => {
          toastSuccess(t("otpSentSuccessfully"));
        }).catch(error => {
          toastError(error.message);
          console.log(error)
        });

      }).catch((err: any) => console.log("err", err))
    }
  }

  useEffect(() => {
    if (serverError) {
      setPhoneError(false)
      setServerError(false)
    }
  }, [watch('otp')])

  useEffect(() => {
    if (!cognitoModel && !currentUser) {
      navigate('/')
    }
    if (cognitoModel && !changePhoneNumber) {
      setValue('phoneNumber', cognitoModel.phone_number)
    }
    if (currentUserDetail && !changePhoneNumber) {
      setValue('phoneNumber', currentUserDetail.phone_number)
    }
  }, [cognitoModel, currentUser])

  useEffect(() => {
    setValue('otp', otp);
  }, [otp])

  return (
    <>
      {cognitoModel && !currentUserDetail && (
        <span className="flex flex-col items-center px-2">
          <img src={BeemgPrimaryLogo} alt="Beemg Logo" className='h-20 w-20 mb-2' />
          <span className="text-4xl sm:text-normal uppercase font-bold mb-4">
            {t('signUp')}
          </span>
          <span data-testid="txt_phone" className="text-lg sm:text-normal font-semibold mb-4">
            {t('phoneNumberVerification')}
          </span>
          {serverError && (
            <span
              data-testid="error_wrongCode"
              id="error_wrongCode"
              className="bg-red-100 text-yellow-900 rounded-full py-1 mt-3 px-10 mx-auto text-center">
              {t('wrongCodeEntered')}
            </span>
          )}
          <span className="flex items-center flex-col">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-2">
                <div className="max-w-96">
                  {!changePhoneNumber && (
                    <>
                      <span
                        className="flex justify-end text-sm mb-1"
                        id="btn_forgotPassword">
                        {t('codeSentTo')}{' '}
                        <span className="font-bold pl-1">
                          {cognitoModel?.phone_number}
                        </span>
                      </span>
                      <ShowTimer nextExpiryDateTime={nextExpiryDateTime} />
                    </>
                  )}

                  <div className="col-span-6 w-full">
                    {!changePhoneNumber && (
                      <div className="m-2  flex items-center w-full ">
                        <span id="phoneNo" className="font-semibold">
                          {cognitoModel?.phone_number}
                        </span>
                        <span
                          data-testid="edit_phoneNo"
                          id="edit_phoneNo"
                          onClick={() => {
                            setChangePhoneNumber(true)
                            setValue('otp', '')
                          }}>
                          <Icon
                            className="cursor-pointer"
                            icon="EDIT"
                            size="small"
                          />
                        </span>
                      </div>
                    )}
                    {changePhoneNumber ? (
                      <div id="input_phoneNo" className="my-2">
                        <Controller
                          control={control}
                          name={'phoneNumber'}
                          render={({field: {onChange, value, name, ref}}) => (
                            <PhoneNumberField
                              value={cognitoModel.phone_number}
                              data-testid="input_phoneNo"
                              placeholder={t('phoneNo') + ' *'}
                              onChange={(value: any) => onChange(value)}
                              error={errors.phoneNumber?.message}
                            />
                          )}
                        />
                      </div>
                    ) : (
                      // <Suspense fallback={<Loading />}>
                      <OTPInput
                      {...register('otp')}
                      numInputs={6}
                      data-testid="input_OTP"
                      onChange={(e: any) => { setOtp(e); setOtpCount(e.length) }}
                      renderSeparator={<span>-</span>}
                      value={otp}
                      inputType='number'
                      renderInput={(props) => <input {...props} />}
                      shouldAutoFocus
                    />
              
                    )}
                  </div>

                  {changePhoneNumber && isValid && (
                    <div className="flex gap-x-4 justify-end">
                      <span
                        data-testid="btn_cancel"
                        id="btn_cancel"
                        className="cursor-pointer"
                        onClick={() => setChangePhoneNumber(false)}>
                        {t('cancel')}
                      </span>
                      <span
                        className="flex justify-end text-red-500 mb-2 cursor-pointer"
                        id="btn_sendOTP"
                        data-testid="btn_sendOTP"
                        onClick={() => {
                          setChangePhoneNumber(false)
                          sendOTP(getValues('phoneNumber'))
                        }}>
                        {t('sendOTP')}
                      </span>
                    </div>
                  )}
                  {!changePhoneNumber && isValid && (
                    <span
                      className="flex justify-end text-red-500 my-2 cursor-pointer"
                      id="btn_resendOTP"
                      data-testid="input_resendOTP"
                      onClick={resendCode}>
                      {t('resendCode')} ?
                    </span>
                  )}
                </div>
              </div>

              {!changePhoneNumber && (
                <div className="flex justify-center">
                  <Button
                    data-testid="btn_next2"
                    id="btn_next2"
                    className="w-64 my-2 mt-4"
                    color="save"
                    disabled={changePhoneNumber || otpCount !== 6 || isExpired}
                    submit>
                    <div>{t('next') + ' 2/4 '}</div>
                  </Button>
                </div>
              )}
            </form>
          </span>
        </span>
      )}
      {currentUserDetail && (
        <SignedInPhoneVerify
          currentUserDetail={currentUserDetail}
          // nextExpiryDateTime={nextExpiryDateTime}
        />
      )}
    </>
  )
}

/**
 * A component for phone verification when the user is signed in.
 *
 * @param {object} props - The component props.
 * @param {object} props.currentUserDetail - The user's current detail.
 * @param {Date} props.nextExpiryDateTimeVal - The date and time when the OTP will expire.
 * @returns {JSX.Element} The component.
 */
function SignedInPhoneVerify({ currentUserDetail  }: { currentUserDetail: any }) {
  const { sendOTP1, signedUpResendCode, submit, register, handleSubmit, control, nextExpiryDateTimeVal, getValues, setValue, errors, isValid, phoneError, watch, setPhoneError } = useSignedInPhoneVerifyHook(currentUserDetail)
  const { isExpired } = usePhoneVerificationTimerHook(nextExpiryDateTimeVal)
  const [serverError, setServerError] = useState(false);
  const [changePhoneNumber, setChangePhoneNumber] = useState(false);
  const [otp, setOtp] = useState('')
  const currentUser = useAppSelector(getCurrentUser)
  const { t } = useTranslation();

  if (phoneError && !serverError) {
    setServerError(true);
  }
  useEffect(() => {
    setValue('otp', otp);
  }, [otp])

  useEffect(() => {
    if (serverError) {
      setPhoneError(false)
      setServerError(false)
    }
  }, [watch('otp')])

  useEffect(() => {

    if (currentUserDetail && !changePhoneNumber) {
      setValue('phoneNumber', currentUserDetail.phone_number)
    }
  }, [currentUserDetail, currentUser])

  return (
    <span className="flex items-center flex-col px-2">
      <img src={BeemgPrimaryLogo} alt="Beemg Logo" className='h-20 w-20 mb-2' />
      <span className="text-lg sm:text-normal font-semibold mb-4">{t('phoneNumberVerification')}</span>
      {serverError && !changePhoneNumber &&
        <span data-testid="error_wrongCode" id="error_wrongCode" className="bg-red-100 text-yellow-900 rounded-full py-1 mt-3 px-10 mx-auto text-center">{t('wrongCodeEntered')}</span>
      }
      <span className="flex items-center flex-col">
        <form onSubmit={handleSubmit(submit)}>
          <div className="mt-2">
            <div className="w-96">
              {!changePhoneNumber &&
                <>
                  <span className="flex justify-end text-sm mb-1" id="btn_forgotPassword">
                    {t('codeSentTo')} <span className="font-bold pl-1">{currentUserDetail?.phone_number}</span>
                  </span>
                  <ShowTimer nextExpiryDateTime={nextExpiryDateTimeVal} />
                </>
              }

              <div className="col-span-6 w-full">
                {!changePhoneNumber &&
                  <div className="m-2  flex items-center w-full ">
                    <span id="phoneNo" className='font-semibold'>{currentUserDetail?.phone_number}</span>
                    <span data-testid="edit_phoneNo" id="edit_phoneNo" onClick={() => { setChangePhoneNumber(true) }}><Icon className='cursor-pointer' icon="EDIT" size='small' /></span>
                  </div>
                }
                {changePhoneNumber
                  ? <div id="input_phoneNo" className="my-2">
                    <Controller
                      control={control} name={"phoneNumber"} render={({ field: { onChange, value, name, ref } }) => (
                        <PhoneNumberField
                          value={currentUserDetail.phone_number} data-testid="input_phoneNo" placeholder={t('phoneNo') + ' *'} onChange={(value: any) => onChange(value)}
                          error={errors.phoneNumber?.message}
                        />

                      )}
                    />
                  </div>
                  :
                  // <Suspense fallback={<Loading />}>
                    <OTPInput
                    {...register('code')}
                    data-testid="input_OTP"
                    numInputs={6}
                    onChange={setOtp}
                    renderSeparator={<span>-</span>}
                    value={otp}
                    inputType='number'
                    renderInput={(props) => <input {...props} />}
                    shouldAutoFocus
                  />
                  // </Suspense>
                }

              </div>

              {changePhoneNumber && isValid &&
                <div className='flex gap-x-4 justify-end'>
                  <span data-testid="btn_cancel" id="btn_cancel" className='cursor-pointer' onClick={() => setChangePhoneNumber(false)}>{t('cancel')}</span>
                  <span className="flex justify-end text-red-500 mb-2 cursor-pointer" id="btn_sendOTP" data-testid="btn_sendOTP" onClick={() => {
                    setChangePhoneNumber(false);
                    sendOTP1(getValues('phoneNumber'));
                  }}>
                    {t('sendOTP')}
                  </span>
                </div>
              }

              {!changePhoneNumber && isValid &&
                <span className="flex justify-end text-red-500 my-2 cursor-pointer" id="btn_resendOTP" data-testid="input_resendOTP" onClick={signedUpResendCode}>
                  {t('resendCode')} ?
                </span>
              }

            </div>
          </div>

          {!changePhoneNumber &&
            <div className="flex justify-center">
              <Button data-testid="btn_next2" id="btn_next2" className="w-64 my-2 mt-4" color="save" disabled={changePhoneNumber || isExpired} submit><div>{t('next')}</div></Button>
            </div>}
        </form>
      </span>
    </span>
  )
}

/**
 * A component that displays a timer for the phone verification OTP.
 *
 * @param {{ nextExpiryDateTime: Date }} props - The props for the component.
 * @param {Date} props.nextExpiryDateTime - The date and time when the verification code expires.
 * @returns {JSX.Element} The JSX element for the component.
 */
function ShowTimer({ nextExpiryDateTime }: { nextExpiryDateTime: Date }) {
  const { displayTimerFormatted } = usePhoneVerificationTimerHook(nextExpiryDateTime)

  return <>
    {displayTimerFormatted !== '0' && <div id="otp_timer" className="flex justify-end font-bold text-red-500 mb-2">
      {displayTimerFormatted} </div>}
  </>
}

/**
 * A component that displays the phone verification screen, which includes a form
 * to input the phone verification code sent to the user's phone number.
 *
 * If the user has already verified their phone number, it will display a loading
 * screen instead.
 *
 * @returns {JSX.Element} The JSX element for the component.
 */
function PhoneVerification() {
  const currentUser: User = useSelector(getCurrentUser)

  if (currentUser?.phoneVerified === Verified.Complete) {
    return <Loading />
  }

  return (
    <InitialPage>
      <span
        className="flex items-center min-h-screen">
        <PhoneVerify />
      </span>
    </InitialPage>
  )
}

export default PhoneVerification
