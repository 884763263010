/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {
  ChatEntry,
  ChatProps,
  formatChatMessageLinks,
  GridLayoutProps,
  ReceivedChatMessage,
  TrackLoop,
  useDataChannel,
  useLocalParticipant,
} from '@livekit/components-react'
import * as React from 'react'
import { useGetHostAndCoHost } from '../../livekitHooks'
import { Track } from 'livekit-client'
import { useTranslation } from 'react-i18next'
import { cloneSingleChild } from 'livekit/LiveKit.utils'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToolTip } from 'base/tooltip/tooltip'

interface ChatMessages {
  id: string
  timestamp: number
  message: string
  from: any
}
/**
 * CustomLiveShoppingLayout component displays the nested participants in a grid where every participant has the same size.
 *
 * @param {GridLayoutProps} props - The props object containing tracks and additional props.
 * @return {JSX.Element} The rendered GridLayout component.
 */

export function CustomLiveShoppingLayout({ tracks, ...props }: GridLayoutProps) {
  const { hostAndCoHosts } = useGetHostAndCoHost()

  const hostCohostTracks = React.useMemo(() => {
    return tracks.filter((track) => hostAndCoHosts.includes(track.participant.sid))
  }, [tracks, hostAndCoHosts])

  const cameraEnabledTrack = React.useMemo(() => {
    const cameraEnabledTracks = hostCohostTracks
      .filter((track) => track.source === Track.Source.Camera)
      .map((track) => ({
        trackSid: track.publication?.trackSid ?? track.participant.sid,
        participantSid: track.participant.sid,
        source: track.source,
        participant: track.participant,
      }))
    if (cameraEnabledTracks.length > 0) {
      return cameraEnabledTracks[0]
    } else {
      return undefined
    }
  }, [hostCohostTracks])

  const screenSharedTrack = React.useMemo(() => {
    const screenSharedTracks = hostCohostTracks
      .filter((track) => track.source === Track.Source.ScreenShare)
      .map((track) => ({
        trackSid: track.publication?.trackSid ?? track.participant.sid,
        participantSid: track.participant.sid,
        source: track.source,
        participant: track.participant,
      }))
    if (screenSharedTracks.length > 0) {
      return screenSharedTracks[0]
    } else {
      return undefined
    }
  }, [hostCohostTracks])

  function LiveShoppingChat({
    messageFormatter,
    messageDecoder,
    messageEncoder,
    className,
    isPipEnabled,
    ...props
  }: ChatProps & { isPipEnabled?: boolean }) {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const ulRef = React.useRef<HTMLUListElement>(null)

    const { message: chatmsg, send, isSending } = useDataChannel('livekitChat')

    const [messageLengthExceed, setMessageLengthExceed] = React.useState(false)
    const [chatMessages, setChatMessages] = React.useState<ChatMessages[]>([])
    const { localParticipant } = useLocalParticipant()

    const { t } = useTranslation()

    React.useEffect(() => {
      if (chatmsg) {
        // Decode chat messages
        const chatMessage = JSON.parse(new TextDecoder().decode(chatmsg.payload)) as ReceivedChatMessage

        const newMessage: ChatMessages = {
          id: chatMessage.id,
          timestamp: chatMessage.timestamp,
          message: chatMessage.message,
          from: chatmsg.from,
        }
        setChatMessages((prev) => [...prev, newMessage])
      }
    }, [chatmsg])

    const scrollToBottom = () => {
      if (ulRef && ulRef.current) {
        ulRef.current.scrollTo({ top: ulRef.current.scrollHeight })
      }
    }

    function handleSubmit(event: React.FormEvent) {
      event.preventDefault()
      if (inputRef.current && ulRef.current) {
        const message = inputRef.current.value.trim()
        if (message !== '') {
          if (message.length > 500) {
            setMessageLengthExceed(true)
            return
          }
          setMessageLengthExceed(false)

          const encodedMessage = new TextEncoder().encode(
            JSON.stringify({ id: localParticipant?.sid, message, timestamp: Date.now() })
          )

          const sentMessage: ChatMessages = {
            id: localParticipant?.sid,
            timestamp: Date.now(),
            message: message,
            from: { name: 'You' },
          }

          send(encodedMessage, { reliable: true })
          setChatMessages((prev) => [...prev, sentMessage])
          inputRef.current.value = ''
          inputRef.current.focus()
        }
      }
    }

    function handleMessageChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (event.target.value.length <= 500) {
        setMessageLengthExceed(false)
      }
    }

    React.useEffect(() => {
      scrollToBottom()
    }, [chatMessages])

    return (
      <>
        <div
          {...props}
          className={`flex flex-col gap-2 aspect-[9/10] bg-white border-gray-200 border-solid border-2 rounded-t-md`}
        >
          {/* Top */}
          <div className="flex justify-between bg-amber-500 p-1 rounded-t-md">
            <div>
              <span id="chatHeader" className="text-white">
                {t('groupChat')}
              </span>
            </div>
          </div>

          {/* Messages */}
          <div className="overflow-y-auto py-2 h-full flex flex-col gap-2 w-full bg-white capitalize">
            <ul ref={ulRef}>
              {props.children
                ? chatMessages.map((msg, idx) =>
                    cloneSingleChild(props.children, {
                      entry: msg,
                      key: idx,
                      messageFormatter,
                    })
                  )
                : chatMessages.map((msg, idx, allMsg) => {
                    let slicedName = msg.from?.name || ''
                    if (slicedName.length > 30) {
                      slicedName = slicedName.slice(0, 30) + '...'
                    }
                    const hideName = idx >= 1 && allMsg[idx - 1].from?.name === slicedName
                    // If the time delta between two messages is bigger than 60s show timestamp.
                    const hideTimestamp = idx >= 1 && msg.timestamp - allMsg[idx - 1].timestamp < 60_000

                    const slicedMsg = { ...msg, from: { ...msg.from, name: slicedName } } as ChatMessages

                    return (
                      <ChatEntry
                        key={idx}
                        hideName={hideName}
                        hideTimestamp={hideName === false ? false : hideTimestamp}
                        entry={slicedMsg}
                        messageFormatter={formatChatMessageLinks}
                      />
                    )
                  })}
            </ul>
          </div>

          {/* Send */}
          <div className="flex">
            <form className="w-full" onSubmit={handleSubmit}>
              <div className="flex flex-row w-full p-1">
                <input
                  disabled={isSending}
                  ref={inputRef}
                  className="bg-transparent relative flex flex-1 w-full rounded-l-md rounded-r-none py-2 pl-4 pr-10 text-gray-400 placeholder:text-sm placeholder-gray-400 text-base focus:outline-none focus:ring-1 focus:ring-yellow-400 focus:border-transparent border border-gray-300 h-full"
                  type="text"
                  id="input_typeMsg"
                  placeholder={t('typeAMessage')}
                  onChange={handleMessageChange}
                />
                <div className="flex flex-col justify-center rounded-r-md rounded-l-none border-r border-t border-b border-gray-300">
                  <button disabled={isSending} type="submit" className="p-1">
                    <ToolTip tip={t('send')}>
                      <FontAwesomeIcon id="btn_sendMessage" icon={faPaperPlane} className="text-gray-500" />
                    </ToolTip>
                  </button>
                </div>
              </div>
              {messageLengthExceed && (
                <div id="msgLengthError" className="text-red-500 p-0.5 text-sm">
                  {t('messageLengthExceeds')} 500 {t('characters')}
                </div>
              )}
            </form>
          </div>
        </div>
      </>
    )
  }

  console.log('hostCohostTracks', hostCohostTracks, cameraEnabledTrack, screenSharedTrack)

  return (
    <div className="flex flex-row w-3/4 h-3/4">
      <div className="w-1/5 h-full"></div>

      {/* <div className="w-3/5 h-full flex items-center justify-center">
        <div ref={gridWrapperRef} className="pt-4 relative">
          <TrackLoop tracks={hostCohostTracks}>{props.children}</TrackLoop>
        </div>
      </div> */}

      <div className="w-3/5 h-full flex items-center justify-center">
        <div className="bg-gray-200  flex items-center justify-center rounded-md">
          <TrackLoop tracks={hostCohostTracks}>{props.children}</TrackLoop>
        </div>
      </div>

      <div className="w-3/5 h-full flex items-center justify-center">
        <div className="flex items-center justify-center"></div>
        <div className="flex-grow p-4 overflow-y-auto">
          <LiveShoppingChat className="absolute bottom-0 left-0 right-0 p-4 aspect-auto" />
        </div>
      </div>

      <div className="w-2/5 h-full"></div>
    </div>
  )
}
