/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import * as React from 'react'

import { createLocalVideoTrack, type LocalAudioTrack, type LocalVideoTrack } from 'livekit-client'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { useCustomRoomContext } from 'livekit/room/CustomRoomContext'
import { useMaybeRoomContext, useMediaDeviceSelect } from '@livekit/components-react'
import { useControlBarAccessHooks } from 'livekit/livekitHooks'
import { t } from 'i18next'

export interface StreamUrlResponse {
  url: string
  streamKey: string
}
/** @public */
export interface MediaDeviceSelectProps extends React.HTMLAttributes<HTMLUListElement> {
  kind: MediaDeviceKind
  onActiveDeviceChange?: (deviceId: string) => void
  onDeviceListChange?: (devices: MediaDeviceInfo[]) => void
  onDeviceSelectError?: (e: Error) => void
  initialSelection?: string[]

  /** will force the browser to only return the specified device
   * will call `onDeviceSelectError` with the error in case this fails
   */
  exactMatch?: boolean
  track?: LocalAudioTrack | LocalVideoTrack
  /**
   * this will call getUserMedia if the permissions are not yet given to enumerate the devices with device labels.
   * in some browsers multiple calls to getUserMedia result in multiple permission prompts.
   * It's generally advised only flip this to true, once a (preview) track has been acquired successfully with the
   * appropriate permissions.
   *
   * @see {@link MediaDeviceMenu}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices | MDN enumerateDevices}
   */
  requestPermissions?: boolean
  icon?: any
  getStreamUrl?: () => Promise<StreamUrlResponse>
}

/**
 * Renders a list of media devices of a specific kind and allows the user to select one.
 *
 * @param {MediaDeviceSelectProps} props - The properties for the media device select.
 * @param {MediaDeviceKind} props.kind - The kind of media device.
 * @param {string} props.initialSelection - The initial selection of the media device.
 * @param {function} props.onActiveDeviceChange - The callback function when the active device changes.
 * @param {function} props.onDeviceListChange - The callback function when the device list changes.
 * @param {function} props.onDeviceSelectError - The callback function when there is an error selecting a device.
 * @param {boolean} props.exactMatch - Whether to force the browser to only return the specified device.
 * @param {LocalAudioTrack | LocalVideoTrack} props.track - The track of the media device.
 * @param {boolean} props.requestPermissions - Whether to request permissions for the media devices.
 * @param {any} props.icon - The icon to display for the device.
 * @return {JSX.Element} The media device select component.
 */

export function CustomVideoDeviceSelect({
  kind,
  initialSelection = [],
  onActiveDeviceChange,
  onDeviceListChange,
  onDeviceSelectError,
  exactMatch,
  track,
  requestPermissions,
  getStreamUrl,
  ...props
}: MediaDeviceSelectProps) {
  const room = useMaybeRoomContext()

  const { devices, setActiveMediaDevice, activeDeviceId } = useMediaDeviceSelect({
    kind,
    room,
    track,
    requestPermissions,
  })

  const { dualSelection, setDualSelection } = useCustomRoomContext()

  const [selectedDevices, setSelectedDevices] = React.useState<string[]>([])
  const [streamKey, setStreamKey] = React.useState('')
  const [url, setUrl] = React.useState('')

  React.useEffect(() => {
    if (activeDeviceId && !selectedDevices.includes(activeDeviceId) && selectedDevices.length === 0) {
      setSelectedDevices([activeDeviceId])
    }
  }, [selectedDevices, activeDeviceId])

  React.useEffect(() => {
    if (selectedDevices.length === 0) {
      if (Array.isArray(initialSelection)) {
        setSelectedDevices(initialSelection)
      } else {
        setSelectedDevices([initialSelection])
      }
    }
  }, [initialSelection])

  React.useEffect(() => {
    if (typeof onDeviceListChange === 'function') {
      onDeviceListChange(devices)
    }
  }, [onDeviceListChange, devices])

  React.useEffect(() => {
    if (selectedDevices.length > 0) {
      onActiveDeviceChange?.(selectedDevices[0])
    }
  }, [selectedDevices, onActiveDeviceChange])

  React.useEffect(() => {
    if (selectedDevices.length > 0) {
      onActiveDeviceChange?.(selectedDevices[0])
    }
  }, [selectedDevices, onActiveDeviceChange])

  const muteAllDevices = async () => {
    if (!room) {
      return
    }
    const trackPublications = Array.from(room.localParticipant.trackPublications.values())
    for (let i = 0; i < trackPublications.length; i++) {
      const track = trackPublications[i].track // add a null check here
      if (track) {
        room.localParticipant.unpublishTrack(track)
        return
      }
    }
  }

  const isAlreadySelected = (deviceId: string) => selectedDevices.includes(deviceId)

  const unpublishTrackForDeviceId = async (deviceId: string) => {
    if (deviceId && room) {
      const trackPublications = Array.from(room.localParticipant.trackPublications.values())
      for (let i = 0; i < trackPublications.length; i++) {
        const _deviceId = await trackPublications[i]?.track?.getDeviceId()

        if (deviceId === _deviceId && trackPublications[i]?.track) {
          const track = trackPublications[i].track // add a null check here
          if (track) {
            room.localParticipant.unpublishTrack(track)
            return
          }
        }
      }
    }
  }

  const removeDevice = async (deviceId: string) => {
    setSelectedDevices(selectedDevices.filter((d) => d !== deviceId))

    await unpublishTrackForDeviceId(deviceId)
  }

  const handleActiveDeviceChange = async (deviceId: string) => {
    if (!room) return

    /*
     * 1. if no track is existing, create one
     * 2. if once trace exists, add 2nd track
     * 3. if 2nd track exists, replace it
     * 4. if deselect track, remove it
     */

    if (selectedDevices.length > 1 && isAlreadySelected(deviceId)) {
      await removeDevice(deviceId)
      return
    }

    try {
      const localParticipant = room.localParticipant

      // Manage dual or single device selection
      let updatedSelections = [...selectedDevices]
      if (dualSelection) {
        if (!updatedSelections.includes(deviceId)) {
          if (updatedSelections.length < 2) {
            updatedSelections.push(deviceId) // Add if less than two
          } else {
            updatedSelections[1] = deviceId // Replace the second track if two exist
          }
        }
      } else {
        updatedSelections = [deviceId] // Single selection
      }

      setSelectedDevices(updatedSelections)
      await setActiveMediaDevice(deviceId, { exact: exactMatch })

      // Add or replace tracks in LiveKit

      for (let i = 0; i < updatedSelections.length; i++) {
        const currentDeviceId = updatedSelections[i]

        const trackPublications = Array.from(localParticipant.trackPublications.values())
        const existingDeviceIds = []
        for (let i = 0; i < trackPublications.length; i++) {
          const pub = trackPublications[i]
          const deviceId = await pub.track?.getDeviceId()
          existingDeviceIds.push(deviceId)
        }

        if (!existingDeviceIds.includes(currentDeviceId)) {
          const newTrack = await createLocalVideoTrack({ deviceId: currentDeviceId })
          if (newTrack) {
            if (trackPublications.length > 1 && trackPublications[1].track) {
              await localParticipant.unpublishTrack(trackPublications[1].track)
            }

            await localParticipant.publishTrack(newTrack)
          }
        }
      }

      // Notify parent component of the active device change
      if (updatedSelections.length > 0) {
        onActiveDeviceChange?.(updatedSelections[0])
      }
    } catch (e) {
      if (e instanceof Error) {
        onDeviceSelectError?.(e)
      } else {
        throw e
      }
    }
  }

  const handleGetStreamUrl = async () => {
    try {
      if (!getStreamUrl) {
        return
      }

      // Check if the stream key already exists in localStorage
      const existingStreamKey = localStorage.getItem('streamKey')
      const existingStreamUrl = localStorage.getItem('streamUrl')

      if (existingStreamKey && existingStreamUrl) {
        setStreamKey(existingStreamKey)
        setUrl(existingStreamUrl)
        return
      }

      // Fetch new stream key and URL if not already stored
      const streamResponse = await getStreamUrl()
      setStreamKey(streamResponse.streamKey)
      setUrl(streamResponse.url)

      // Store the stream key and URL in localStorage
      localStorage.setItem('streamKey', streamResponse.streamKey)
      localStorage.setItem('streamUrl', streamResponse.url)
    } catch (error) {
      console.error('Error fetching stream URL:', error)
    }
  }

  // const isActive = (deviceId: string) => selectedDevices.includes(deviceId)
  const { isHostOrCoHostAvailableRoom } = useControlBarAccessHooks()
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const handleAddExternalCameraClick = async () => {
    await handleGetStreamUrl()
    setIsModalOpen(true)
  }

  return (
    <div className="p-2 bg-gray-200">
      {/* Selection Mode Toggle */}
      {isHostOrCoHostAvailableRoom && (
        <div className="flex items-center mb-2 mt-1">
          <label htmlFor="dual-selection" className="mx-1 text-sm mr-3">
            {t('enableDualDeviceSelection')}
          </label>
          <div
            onClick={() => setDualSelection(!dualSelection)}
            className={`relative w-8 h-5 cursor-pointer rounded-full transition-colors ${
              dualSelection ? 'bg-yellow-400' : 'bg-gray-300'
            }`}
          >
            <div
              className={`absolute top-1/2 transform -translate-y-1/2 w-4 h-4 bg-white rounded-full shadow transition-transform ${
                dualSelection ? 'translate-x-4' : 'translate-x-0'
              }`}
            />
          </div>
        </div>
      )}

      {/* Device Selection Menu */}
      <ul className="flex flex-col gap-y-2 bg-white border border-gray-400 rounded-md p-2 mb-4" {...props}>
        {devices.map((device) => (
          <li key={device.deviceId} id={device.deviceId} role="option">
            <div className="flex flex-row items-center gap-x-2 hover:bg-gray-200 rounded-md px-2 py-1">
              <button
                className={`text-black text-sm ${selectedDevices.includes(device.deviceId) ? 'font-bold' : ''}`}
                onClick={() => handleActiveDeviceChange(device.deviceId)}
              >
                {device.label}
              </button>
              {selectedDevices.includes(device.deviceId) && (
                <FontAwesomeIcon icon={faCircleCheck} className="bg-transparent" />
              )}
            </div>
          </li>
        ))}
        {!isModalOpen && (
          <li>
            <button
              className="w-full text-sm bg-gray-400 text-white py-2 rounded-md hover:bg-amber-400 transition border border-gray-400"
              onClick={handleAddExternalCameraClick}
            >
              {t('addExternalCamera')}
            </button>
          </li>
        )}
      </ul>

      {/* Display Modal */}
      {isModalOpen && (
        <div className="flex flex-col gap-y-2 bg-white border border-gray-400 rounded-md p-2 mb-4">
          <div className="flex flex-row items-center gap-x-2 px-2 py-1">
            <span className="text-black text-sm font-semibold">{t('Stream Key')}:</span>
            <p className="text-black text-sm">{streamKey}</p>
          </div>
          <div className="flex flex-row items-center gap-x-2 px-2 py-1">
            <span className="text-black text-sm font-semibold">{t('Stream URL')}:</span>
            <p className="text-black text-sm">{url}</p>
          </div>
        </div>
      )}
    </div>
  )
}
