/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { Track } from 'livekit-client'
import React from 'react'
import { useLocalParticipantPermissions } from '@livekit/components-react'
import { CustomDisconnectButton } from 'livekit/controlbar/CustomDisconnectButton'
import { CustomMediaDeviceMenu } from 'livekit/controlbar/CustomMediaSelectMenu'
import { StreamUrlResponse } from 'livekit/controlbar/CustomVideoDeviceSelect'
import { CustomTrackToggle } from 'livekit/controlbar/LivekitCustomTrackToggle'
import { useControlBarAccessHooks, usePermissionCheckHooks } from 'livekit/livekitHooks'
import { MeetingType } from 'Models/meeting.model'

/** @public */
export type ControlBarControls = {
  handRaise?: boolean
  microphone?: boolean
  camera?: boolean
  leave?: boolean
}

/** @public */
export interface ControlBarProps extends React.HTMLAttributes<HTMLDivElement> {
  variation?: 'minimal' | 'verbose' | 'textOnly'
  controls?: ControlBarControls
  onLeave?: () => void
  onEnd?: () => void
  meetingType: MeetingType
  getStreamUrl: () => Promise<StreamUrlResponse>
}

export const buttonStyles =
  'py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-full first:ml-0 last:rounded-r-full border font-medium bg-gray-200 text-gray-700 align-middle hover:bg-gray-50 focus:z-10 transition-all text-sm'
export const buttonIconSize = 'small'

/**
 * The ControlBar prefab component gives the user the basic user interface
 * to control their media devices and leave the room.
 *
 * @param {ControlBarProps} props - The properties for the control bar.
 * @param {string} props.variation - The variation of the control bar, either 'minimal', 'verbose', or 'textOnly'.
 * @param {ControlBarControls} props.controls - An object specifying which controls to display.
 * @param {React.HTMLAttributes<HTMLDivElement>} props...props - Additional props for the control bar div.
 * @return {JSX.Element} The JSX element for the control bar.
 */

export function CustomLiveShoppingControlbar({
  variation,
  controls,
  meetingType,
  getStreamUrl,
  ...props
}: ControlBarProps) {
  const { isHostOrCoHostAvailableRoom } = useControlBarAccessHooks()
  const { isAudioPermissionGrantedError, isVideoPermissionGrantedError } = usePermissionCheckHooks()
  const visibleControls = { leave: true, ...controls }
  const localPermissions = useLocalParticipantPermissions()

  if (!localPermissions) {
    visibleControls.camera = false
    visibleControls.microphone = false
  }

  return (
    <div {...props} className={'bg-white flex items-center justify-center gap-1 relative mt-2'}>
      {visibleControls.microphone && isHostOrCoHostAvailableRoom && (
        <div className="flex items-center">
          <CustomTrackToggle
            source={Track.Source.Microphone}
            meetingType={meetingType}
            className={`py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-full first:ml-0 border font-medium bg-gray-200 text-gray-700 align-middle ${
              !isAudioPermissionGrantedError ? 'hover:bg-gray-50' : 'cursor-not-allowed'
            } focus:z-10 transition-all text-sm`}
          />
          {<CustomMediaDeviceMenu kind="audioinput" bottom="56px" />}
        </div>
      )}
      {visibleControls.camera && isHostOrCoHostAvailableRoom && (
        <div className="flex items-center">
          <CustomTrackToggle
            source={Track.Source.Camera}
            meetingType={meetingType}
            className={`py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-full first:ml-0 border font-medium bg-gray-200 text-gray-700 align-middle ${
              !isVideoPermissionGrantedError ? 'hover:bg-gray-50' : 'cursor-not-allowed'
            } focus:z-10 transition-all text-sm`}
          />
          {<CustomMediaDeviceMenu kind="videoinput" bottom="56px" getStreamUrl={getStreamUrl} />}
        </div>
      )}
      {/* {visibleControls.screenShare && browserSupportsScreenSharing && !isAudioOnly && (
        <CustomTrackToggle
          source={Track.Source.ScreenShare}
          captureOptions={{ audio: true, selfBrowserSurface: 'include' }}
          meetingType={meetingType}
          className={ `py-3 px-4 justify-center items-center gap-2 -ml-px border rounded-full font-medium bg-gray-200 text-gray-700 align-middle ${
                  !isVideoPermissionGrantedError ? 'hover:bg-gray-50' : 'cursor-not-allowed'
                } focus:z-10 transition-all text-sm`
          }
        />
      )} */}

      {visibleControls.leave && (
        <CustomDisconnectButton
          id="btn_endCall"
          className={buttonStyles}
          onLeave={() => {
            props.onLeave && props.onLeave()
          }}
          onEnd={() => {
            props.onEnd && props.onEnd()
          }}
        />
      )}
    </div>
  )
}
