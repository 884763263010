/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
  createRef,
  useContext,
  // lazy,
  // Suspense
} from 'react'
import {useTranslation} from 'react-i18next'
import {useGetSettingValue} from '../../Services/settingReducer'
import {MeetingDetails} from '../../models/meetingDetails.model'
import {Image as ImageModel} from '../../models/image.model'
import {
  useDeleteImageMutation,
  useUploadEventImageFileMutation,
} from '../../Services/imageApi'
import Loading from '../../Components/base/loading/loading'
import Button from '../../Components/base/button/button'
import {ToolTip} from '../../Components/base/tooltip/tooltip'
import Icon from '../../Components/base/icon/icon'
import {toastError, toastSuccess} from '../../Components/toast'
import {Modal, ModalCloseButton} from '../../Components/base/modal/modal'
import useToggle from '../../Components/_utils/useToggle'
import Loader from '../../Components/base/loader/loader'
import 'cropperjs/dist/cropper.css'
import Cropper from 'react-cropper'
import { ImageVideoContext } from '../../Hooks/MyEvents/ImageVideoContextHook'
import { set } from 'lodash'
import { on } from 'events'
import { use } from 'i18next'
import { formatBytes } from '../../Hooks/UserProfile'

// const Cropper = lazy(() => import("react-cropper"));


  /**
   * Renders an image component to display, upload or delete images of a meeting.
   * @param {MeetingDetails} meetingDetails - The meeting details object.
   * @param {number} orderNumber - The order number of the image.
   * @param {number} width - The width of the image.
   * @param {number} height - The height of the image.
   * @param {number} asprat - The aspect ratio of the image.
   * @param {boolean} isSaveTriggerWeb - A boolean indicating if the save button is pressed.
   * @param {boolean} isTriggerSaveImageAndVideo - A boolean indicating if the save button is pressed.
   * @return {ReactElement} - A React element containing the image component.
   */
export function EventImage(
  
    {
      meetingDetails,
      orderNumber,
      width,
      height,
      asprat,
    }: {
      meetingDetails: MeetingDetails
      orderNumber: number
      width: number
      height: number
      asprat: number
    },)
   {
    const {t} = useTranslation()
    const imageURL = useGetSettingValue('IMAGE_URL')
    const imageSizeData = useGetSettingValue('EVENT_IMAGE_SIZE')
    let imageSizeToUpload = Number(imageSizeData)
    let imageSizeFormat = formatBytes(imageSizeToUpload)


    let {images, isVideoInDB, isVideoSelected, isSaveTriggerWeb, isTriggerSaveImageAndVideo, setIsSaveTriggerWeb, setIsTriggerSaveImageAndVideo, setIsImageInDB, setSelectedImage, setRemoveImage} =  useContext(ImageVideoContext)


    const [deleteImage, {isLoading: meetingImageRemoveLoading}] =
      useDeleteImageMutation<any>()
    const [addImage, {isLoading: meetingImageAddLoading}] =
      useUploadEventImageFileMutation<any>()

    const [isFilePicked, setIsFilePicked] = useState(false)
    const [imageError, setImageError] = useState(false)
    const [isCropped, setIsCropped] = useState(false)
    const cropperRef = createRef<any>()

    const [upImg, setUpImg] = useState({} as any)
    const {toggle, isOpen} = useToggle()
    const [image, setImage] = useState<ImageModel>()
    const [imageForDelete, setImageForDelete] = useState<ImageModel>()
    const [cropData, setCropData] = useState('#')
    const [croppedBlob, setCroppedBlob] = useState<any>()

    useEffect(() => {
      setIsCropped(false)
      setCropData('#')
      setCroppedBlob(undefined)
      setIsFilePicked(false)
      setImageError(false)
      setImage(undefined)
    },[])

    useEffect(() => {
      if (!meetingDetails) {
        return
      }
      const imageInDb = meetingDetails.image.find(
        image => image.orderNumber === orderNumber,
      )
      setImage(imageInDb)
      setImageForDelete(undefined)
    }, [meetingDetails, orderNumber])

    useEffect(() => {
      console.log("meetingDetails image", meetingDetails?.image.find(image=> image.orderNumber === orderNumber))
    }, [meetingDetails])

    useEffect(() => {
      if(croppedBlob && isCropped) {
        let imageSize = croppedBlob.size
        if(imageSize > imageSizeToUpload) {
          console.log("image size is over limit", imageSize)
          setImageError(true)
          setIsCropped(false)
          setCropData('#')
          setCroppedBlob(undefined)
        }
        else {
          setImageError(false)
        }
      }
    }, [croppedBlob])

    /**
     * Resets the state variables for the image cropper, so that it
     * reverts to its initial state.
     */
    const resetImageEditor = () => {
      setIsCropped(false)
      setCropData('#')
      setCroppedBlob(undefined)
      setIsFilePicked(false)
      setImageError(false)
      setIsSaveTriggerWeb(false)
      setIsTriggerSaveImageAndVideo(false)
    }

    const cancel = useCallback(() => {
      resetImageEditor()
      toggle()
    }, [resetImageEditor, toggle])

    const removeCropped = useCallback(() => {
      resetImageEditor()
    }, [resetImageEditor])

    const onSavedClear = useCallback(() => {
      resetImageEditor()
    }, [resetImageEditor])

    const setDeletableImage = useCallback(() => {
      if (image) {
        setImageForDelete(image)
        setImage(undefined)
      }
    }, [image])

    useEffect(() => {
      //all 5 images in database
      if (image) {
        setIsImageInDB(orderNumber)
        console.log('image', image)
      }
      //all 5 images in database
      else if (!image) {
        console.log('Notimage')
        setRemoveImage(orderNumber)
      }
      if (isCropped) {
        console.log("cropped image size ", (croppedBlob?.size/1024/1024).toFixed(2))
        setSelectedImage(orderNumber)
      }
    }, [image, isCropped, orderNumber])

    const save = useCallback(() => {

      if (meetingDetails && croppedBlob) {
        let file: any = new File([croppedBlob], 'cropped.jpg',  { type: "image/jpeg" })

        addImage({
          file,
          meetingDetailsId: meetingDetails.id,
          orderNumber: orderNumber,
        }).then((response: any) => {
          if(response) {
            console.log('response', response)
          toastSuccess(t('eventImageUploadedSuccessfully'))
          onSavedClear()
          }
          else {
            toastError(t('imageSizeExceedsThan'))
            onSavedClear()
          }
        })
      }
    },[croppedBlob, meetingDetails, orderNumber])
    
    /**
     * Process the image deletion and upload process.
     * If imageForDelete is set, it deletes the image and then calls save() to upload the image.
     * If imageForDelete is not set, it directly calls save() to upload the image.
     */
    const saveProcess = () => {
      setIsTriggerSaveImageAndVideo(false)
      setIsSaveTriggerWeb(false)
      if (imageForDelete) {
        deleteImage({
          imageId: imageForDelete.id,
          meetingDetailsId: meetingDetails?.id,
          fileName: imageForDelete.imageName,
        }).then((response: any) => {
          if(response.data){
            toastSuccess(t('eventImageDeletedSuccessfully'))
            setImageForDelete(undefined)
            save()
          }
          else {
            toastError(t('deleteUnsuccessful'))
          }
        })
      }
      else {
        save()
      }
    }

  /**
   * Handles the event when a user selects a file to be uploaded as their event image.
   * If the selected file is a zip, tiff, or NEF file, it shows an error message and resets the image editor.
   * Otherwise, it sets isFilePicked state to true, reads the file as a data url, sets up the image editor with the data url, and toggles the image editor modal.
   * @param {Event} event - The event object containing the selected file.
   */
    const onUploadFileSelected = (event: any) => {
      const fileInput = event.target;
      const selectedFile = fileInput.files[0];
    
      if (selectedFile && selectedFile.name.endsWith('.zip') || selectedFile && selectedFile.name.endsWith('.tiff') || selectedFile && selectedFile.name.endsWith('.NEF')) {
        toastError(t('notSupportedFileFormat'));
        fileInput.value = null;
        resetImageEditor();
      } else {
        setIsFilePicked(true);
        const reader = new FileReader();
        reader.addEventListener('load', () => setUpImg(reader.result));
        reader.readAsDataURL(selectedFile);
        toggle();
      }
    };
    
    useEffect(() => {
      if(isTriggerSaveImageAndVideo) {
        saveProcess()
      }
    }, [isTriggerSaveImageAndVideo])
    /**
     * Gets the cropped image data as a base64 data url.
     * Also sets the cropped image blob and sets isCropped state to true.
     */
    const getCropData = () => {
      if (typeof cropperRef.current?.cropper !== 'undefined') {
        setCropData(
          cropperRef.current?.cropper
            .getCroppedCanvas({
              width: 750,
              height: 750,
              imageSmoothingEnabled: true,
              imageSmoothingQuality: 'high',
            })
            .toDataURL(),
        )
        cropperRef.current?.cropper
          .getCroppedCanvas({
            width: 750,
            height: 750,
            imageSmoothingEnabled: true,
            imageSmoothingQuality: 'high',
          })
          .toBlob((blob: any) => {
            setCroppedBlob(blob)
            setIsCropped(true)
          })
      }
    }

    if (!meetingDetails) {
      return <Loading />
    }

    return (
      <>
        <div>
          {!image && !isCropped && <ShowImagePlaceholder />}
          {image && !isCropped && <ShowDBImage image={image} />}
          {isCropped && <ShowImagePreviewCanvas />}
        </div>

        {/* Crop Image modal */}

        {isFilePicked && <ImageCropModal />}
      </>
    )

  /**
   * Modal component for cropping an image.
   *
   * @return {JSX.Element} The modal component.
   */
    function ImageCropModal() {
      return (
        <>
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            position="large"
            closeOnClickOutside={false}
            animate={true}>
            <ModalCloseButton toggle={cancel} />
            <div className="">
              <div className="flex col-span-2 justify-center">
                {!isCropped && (
                  // <Suspense fallback={<Loading />}>
                    <Cropper
                      ref={cropperRef}
                      className="cropper-container"
                      initialAspectRatio={1}
                      // preview=".img-preview"
                      src={upImg}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                      guides={true}
                      autoCropArea={asprat}
                      aspectRatio={asprat}
                    />
                  // </Suspense>
                )}
              </div>
              <div className="flex mt-3 gap-x-3 justify-end">
                <div className="flex h-auto items-center">
                  {meetingImageAddLoading && <Loader />}
                  <Button
                    id={'btn_ImageCropSave' + orderNumber}
                    color="footerButton"
                    className="mt-2 w-52 md:mt-8 px-10 mx-2 flex justify-center items-center"
                    data-testid="input_save"
                    onClick={() => {

                      getCropData()
                      toggle()
                    }}
                    disabled={meetingImageAddLoading}>
                    {t('crop')}
                  </Button>
                  <Button
                    className="mt-2 w-52 md:mt-8 px-10 flex justify-center items-center"
                    id={'btn_ImageCancel' + orderNumber}
                    color="footerButton"
                    onClick={cancel}>
                    {t('cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )
    }

  /**
   * Renders the image preview after cropping.
   * @returns {JSX.Element} A JSX element containing the image preview.
   */
    function ShowImagePreviewCanvas() {
      return (
        <>
          <div className="relative">
            <Button
              id={'btn_imageEditCropped' + orderNumber}
              onClick={() => removeCropped()}
              className="flex absolute top-0 right-0 z-10 cursor-pointer "
              color="close"
              rounded={true}
              disabled={meetingImageRemoveLoading}
              size="circle">
              {<Icon icon="CLOSE" size="large" className="pb-4" />}
            </Button>
          </div>
          <img
            src={cropData}
            alt="EventImage"
            className={
              orderNumber === 3 || orderNumber === 4
                ? 'h-20 w-30 md:h-20 md:w-30 lg:h-20 lg:w-30 border-2  rounded-xl  flex flex-col items-center justify-center cursor-pointer object-cover'
                : 'h-40 w-30 md:h-40 md:w-30 lg:h-40 lg:w-30 border-2  rounded-xl  flex flex-col items-center justify-center cursor-pointer object-cover'
            }
           
            width={300}
          />
        </>
      )
    }

  /**
   * Renders a placeholder for an image.
   * @returns {JSX.Element} A JSX element containing the image placeholder.
   */
    function ShowImagePlaceholder() {
      return (
        <>
          <label
            htmlFor={'file-upload' + orderNumber}
            className="leading-3">
            <input
              type="file"
              id={'file-upload' + orderNumber}
              accept="image/*"
              className="hidden"
              onChange={onUploadFileSelected}
            />
            {!imageError && (
              <ToolTip keyId='addImage' tip={t('addImage')}>
                <div
                  id={'addImage' + orderNumber}
                  className={
                    orderNumber === 3 || orderNumber === 4
                      ? 'h-20 w-30 md:h-20 md:w-30 lg:h-20 lg:w-30 border-2 border-dashed rounded-xl border-gray-400 flex flex-col items-center justify-center cursor-pointer'
                      : 'h-40 w-30 md:h-40 md:w-30 lg:h-40 lg:w-30 border-2 border-dashed rounded-xl border-gray-400 flex flex-col items-center justify-center cursor-pointer'
                  }>
                  <Icon icon="ADD" size="small" />
                  <span className="text-xs">{t('addImage')}</span>
                </div>
              </ToolTip>
            )}
            {imageError  && (
              <div
              className={
                orderNumber === 3 || orderNumber === 4
                  ? 'h-20 w-30 md:h-20 md:w-30 lg:h-20 lg:w-30 border-2 p-2 border-dashed rounded-xl border-red-500 flex flex-col items-center justify-center text-sm cursor-pointer text-red-500'
                  : 'h-40 w-30 md:h-40 md:w-30 lg:h-40 lg:w-30 border-2 p-2 border-dashed rounded-xl border-red-500 flex flex-col items-center justify-center text-sm cursor-pointer text-red-500'
              }               >
                * {t('warning!SizeExceedsThan')} {imageSizeFormat}
              </div>
            )}
          </label>
        </>
      )
    }

  /**
   * Renders an image uploaded to the database.
   * @param {object} props - Props passed to the component.
   * @param {ImageModel} props.image - Image object from the database.
   * @returns {JSX.Element} A JSX element containing the image.
   */
    function ShowDBImage({image}: {image: ImageModel}) {
      return (
        <>
          <div className="flex relative">
            <Button
              id={'btn_imageEdit' + orderNumber}
              onClick={() => {
                setDeletableImage()
              }}
              className="flex absolute top-0 right-0 z-10 cursor-pointer "
              color="close"
              rounded={true}
              disabled={meetingImageRemoveLoading}
              size="circle">
              {<Icon icon="CLOSE" size="large" className="flex pb-4" />}
            </Button>
          </div>

          {
            <img
              src={imageURL + '/' + image.imageName}
              alt="EventImage"
              className={
                orderNumber === 3 || orderNumber === 4
                  ? 'h-20 w-30 md:h-20 md:w-30 lg:h-20 lg:w-30 border-2  rounded-xl  flex flex-col items-center justify-center cursor-pointer object-cover'
                  : 'h-40 w-30 md:h-40 md:w-30 lg:h-40 lg:w-30 border-2  rounded-xl  flex flex-col items-center justify-center cursor-pointer object-cover'
              }
              width={300}
            />
          }
        </>
      )
    }
  }