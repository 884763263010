/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useParticipantInfo, useParticipantContext, useRoomContext } from '@livekit/components-react'
import React, { useState, useEffect } from 'react'
import { defaultAvatar } from '../audio_conference/CustomAudioParticipantPlaceholder'
import { useCustomGridContext } from '../CustomGridContext'
import ParticipantCount from 'livekit/header/ParticipantCount'
import { useUpdateBidAmount } from 'livekit/livekitHooks'
import { useCustomRoomContext } from '../CustomRoomContext'

interface _customParticipantPlaceholderProps {
  height: number
  width: number
}

/**
 * A React component that represents a custom participant placeholder in a room.
 * It displays the participant's avatar, name, and microphone status, and handles participant metadata and grid context.
 *
 * @param {object} props - The component props.
 * @param {number} props.height - The height of the participant placeholder.
 * @param {number} props.width - The width of the participant placeholder.
 * @return {JSX.Element} The rendered participant placeholder element.
 */

export function CustomLiveShoppingPlaceholder(props: _customParticipantPlaceholderProps) {
  const { metadata } = useParticipantInfo()
  const [metaData] = useState(JSON.parse(metadata ?? '{}'))
  const [isNullPresent, setIsNullPresent] = useState(false)
  const { pinnedParticipants } = useCustomGridContext()
  const { isSpeaking } = useParticipantContext()
  const room = useRoomContext()

  const { bidAmount, setBidAmount, setLastSentBid, profileImg } = useCustomRoomContext()

  const { updateBidAmount } = useUpdateBidAmount()

  useEffect(() => {
    const handleParticipantConnected = (participant: any) => {
      updateBidAmount({ type: 'BID_UPDATE', bidAmount })
    }

    room.on('participantConnected', handleParticipantConnected)

    return () => {
      room.off('participantConnected', handleParticipantConnected)
    }
  }, [room, bidAmount, updateBidAmount])

  useEffect(() => {
    const handleData = (payload: Uint8Array) => {
      const message = JSON.parse(new TextDecoder().decode(payload))
      if (message.type === 'BID_UPDATE') {
        setBidAmount(message.bidAmount)
        setLastSentBid(message.bidAmount)
      }
    }

    room.on('dataReceived', handleData)

    return () => {
      room.off('dataReceived', handleData)
    }
  }, [room])

  useEffect(() => {
    if (metaData?.profileImageUrl && metaData?.profileImageUrl.includes('null')) {
      setIsNullPresent(true)
    }
    if (profileImg && profileImg.includes('null')) {
      setIsNullPresent(true)
    }
  })

  return (
    <>
      <div
        className={`flex flex-col h-full items-center justify-center relative rounded-md ${
          isSpeaking ? 'outline outline-green-400 outline-3' : ''
        }`}
      >
        {/* Participant Count at the top-right corner */}
        <div className="absolute top-2 right-2 bg-gray-600 text-white text-sm px-1.5 py-1 rounded">
          <ParticipantCount />
        </div>

        <div className="relative aspect-[9/11] flex justify-center items-center w-full h-full">
          {!isNullPresent ? (
            <div className="relative z-1 flex justify-center items-center">
              <img className="h-[30%] w-[30%] object-cover rounded-full" src={metaData.profileImageUrl} alt="avatar" />
            </div>
          ) : pinnedParticipants.length > 0 ? (
            <div className="relative z-1 bg-gray-200 flex justify-center items-center h-full w-full">
              <img className="h-[30%] w-[30%] object-cover rounded-full" src={defaultAvatar} alt="avatar" />
            </div>
          ) : (
            <div className="relative z-1 bg-gray-200 flex justify-center items-center h-full w-full">
              <img className="h-[30%] w-[30%] object-cover rounded-full" src={defaultAvatar} alt="avatar" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
