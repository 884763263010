/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable max-lines, react/prop-types */
import * as React from 'react'
import { findDOMNode } from 'react-dom'
import Slide from './slide'
import {
  animate,
  hasOngoingInteraction,
  includes,
  isWhollyInView,
  noop,
  normalizeIndex,
  on,
  onScrollEnd,
  onScrollStart,
  onSwipe,
  values,
} from './utils'

export default class Whirligig extends React.Component {
  static defaultProps = {
    afterSlide: noop,
    animationDuration: 500,
    beforeSlide: noop,
    gutter: '1em',
    nextKeys: ['ArrowRight'],
    onSlideClick: noop,
    prevKeys: ['ArrowLeft'],
    preventAutoCorrect: false,
    preventScroll: false,
    preventSwipe: false,
    snapPositionOffset: 0,
    snapToSlide: false,
    startAt: 0,
    style: {},
    height: 0,
    width: 0,
  }

  /**
   * Whirligig's constructor.
   *
   * We initialize the Whirligig component's state here, binding the
   * `next`, `prev`, and `slideTo` methods to `this` so that they can
   * be passed to the consuming component.
   *
   * @param {Object} props - The Whirligig component's props.
   */
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: props.startAt,
      isAnimating: false,
      visibleSlides: this.props.visibleSlides || 0,
      slideBy: this.props.slideBy || this.props.visibleSlides || 0,
    }

    // We can't do arrow function properties for these since
    // we are passing them to the consuming component and we
    // require the proper context
    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    this.slideTo = this.slideTo.bind(this)
  }

  eventListeners = []
  isScrolling = false
  canSelfCorrect = () =>
    !this.props.preventAutoCorrect &&
    !this.state.isAnimating &&
    !this.isScrolling &&
    !this.isInteracting()

  shouldSelfCorrect = () => this.props.snapToSlide && this.canSelfCorrect()

  /**
   * Called when the component mounts.
   *
   * We cache the DOM node, and detect whether the user is currently interacting
   * with the component. We also cache the number of children, which is used in
   * slideTo and next/prev methods.
   *
   * We add event listeners for onScrollStart, onScrollEnd, onTouchStart, onTouchEnd,
   * and onSwipe events. On scroll start, we set a flag indicating that the user is
   * currently scrolling. On scroll end and touch end, we check if the user is
   * currently interacting and if the component is not currently animating. If
   * those conditions are met, we slide to the nearest slide if the component is
   * configured to do so, otherwise we call the afterSlide callback. On swipe, if
   * the component is not configured to prevent swiping, we slide to the nearest
   * slide in the direction of the swipe.
   *
   * Finally, we call the slideTo method with an index of 0 and { immediate: true },
   * which will cause the component to be scrolled to the first slide.
   */
  componentDidMount() {
    this.DOMNode = findDOMNode(this.whirligig)
    this.isInteracting = hasOngoingInteraction(this.DOMNode)

    // These are not a part of component state since we don't want
    // incure the overhead of calling setState. They are either cached
    // values or state only the onScrollEnd callback cares about and
    // are not important to the rendering of the component.
    this.childCount =
      this.whirligig && this.whirligig.children
        ? this.whirligig.children.length
        : 0

    const slideBy = {
      left: () => -this.state.slideBy,
      right: () => this.state.slideBy,
      up: () => 0,
      down: () => 0,
    }

    this.eventListeners = [
      ...this.eventListeners,

      onScrollStart(() => {
        this.isScrolling = true
      }),

      on('touchstart')(() => {
        this.isScrolling = true
      })(this.whirligig),

      onScrollEnd(
        () => {
          this.isScrolling = false
          if (this.canSelfCorrect()) {
            if (this.props.snapToSlide) {
              this.slideTo(this.getNearestSlideIndex()).catch(noop)
            } else {
              this.props.afterSlide(this.getNearestSlideIndex())
            }
          }
        },
        { target: this.DOMNode },
      ),

      on('touchend')(() => {
        if (this.canSelfCorrect()) {
          this.props.snapToSlide
            ? this.slideTo(this.getNearestSlideIndex()).catch(noop)
            : this.props.afterSlide(this.getNearestSlideIndex())
        }
      })(this.whirligig),

      onSwipe(direction => {
        if (!this.props.preventSwipe && this.props.snapToSlide) {
          this.slideTo(this.state.activeIndex + slideBy[direction]()).catch(
            noop,
          )
        }
      })(this.whirligig),
    ]

    this.slideTo(this.props.startAt, { immediate: true }).catch(noop)
  }

  /**
   * Clean up all event listeners.
   *
   * When the component is being unmounted, this method is called to remove
   * all event listeners from the DOM. This helps prevent memory leaks.
   */
  componentWillUnmount() {
    this.eventListeners.forEach(fn => typeof fn === 'function' && fn())
  }

  // eslint-disable-next-line camelcase
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.slideBy !== prevState.slideByProp ||
      nextProps.visibleSlides !== prevState.visibleSlidesProp
    ) {
      return {
        slideBy: nextProps.slideBy || nextProps.visibleSlides || 1,
        slideByProp: nextProps.slideBy,
        visibleSlidesProp: nextProps.visibleSlides
      };
    }
    return null;
  }

  /**
   * Called when the component updates.
   *
   * We recalculate the number of children in the component. If the user is not
   * currently interacting with the component, and the component is not currently
   * animating, we slide to the nearest slide. If the slideTo prop has changed,
   * we slide to the new slide.
   */
  componentDidUpdate(prevProps) {
    this.childCount =
      this.whirligig && this.whirligig.children
        ? this.whirligig.children.length
        : 0

    if (this.shouldSelfCorrect()) {
      const nearestSlideIndex = this.getNearestSlideIndex()
      nearestSlideIndex !== this.state.activeIndex &&
        this.slideTo(this.getNearestSlideIndex()).catch(noop)
    }

    if (prevProps.slideTo !== this.props.slideTo) {
      this.slideTo(this.props.slideTo).catch(noop)
    }
  }

  handleKeyUp = (
    (nextKeys, prevKeys) =>
      ({ key }) => {
        const isNext = includes(key, nextKeys)
        const isPrev = includes(key, prevKeys)
        this.setState({ isAnimating: true })
        if (isNext) {
          this.next().catch(noop)
        }
        if (isPrev) {
          this.prev().catch(noop)
        }
        return false
      }
  )(this.props.nextKeys, this.props.prevKeys)

  // isAnimating state is the only important state value to the rendering of this component
  shouldComponentUpdate(nextProps, { isAnimating }) {
    const propValues = [...values(this.props), this.state.isAnimating]
    const nextPropValues = [...values(nextProps), isAnimating]
    return !nextPropValues.every((val, i) => val === propValues[i])
  }

  getPartiallyObscuredSlides = () => {
    const { whirligig } = this
    const findFirstObscuredChildIndex = [...whirligig.children].findIndex(
      (child, i, children) =>
        !isWhollyInView(whirligig)(child) &&
        isWhollyInView(whirligig)(children[i + 1]),
    )

    const firstObscuredChildIndex = Math.max(findFirstObscuredChildIndex, 0)

    const findLastObscuredChildIndex = [...whirligig.children].findIndex(
      (child, i, children) =>
        !isWhollyInView(whirligig)(child) &&
        isWhollyInView(whirligig)(children[i - 1]),
    )

    const lastObscuredChildIndex =
      Math.max(findLastObscuredChildIndex, 0) || whirligig.children.length - 1

    return [firstObscuredChildIndex, lastObscuredChildIndex]
  }

  /**
   * Navigate to the next slide.
   *
   * If `slideBy` is `0`, the next slide is the first partially obscured slide.
   * If `infinite` is `true`, the next slide is the first slide if on the last
   * slide, otherwise it is the next slide.
   * If `infinite` is `false`, the next slide is the last slide if on the last
   * slide, otherwise it is the next slide.
   *
   * @returns {Promise<void>}
   */
  next() {
    const { childCount, props, state } = this
    const { activeIndex, slideBy } = state
    const { infinite } = props
    const firstIndex = 0
    const lastIndex = childCount - slideBy

    if (!slideBy) {
      // eslint-disable-next-line no-unused-vars
      const [_, nextSlide] = this.getPartiallyObscuredSlides() // eslint-disable-line @typescript-eslint/no-unused-vars
      const nextInfinteSlide = nextSlide === childCount - 1 ? 0 : nextSlide
      return this.slideTo(infinite ? nextInfinteSlide : nextSlide)
    }

    const nextActiveCandidate = activeIndex + slideBy
    const nextActive = Math.min(nextActiveCandidate, lastIndex)
    const nextActiveInfinite =
      activeIndex === lastIndex ? firstIndex : nextActive
    return this.slideTo(infinite ? nextActiveInfinite : nextActive)
  }

  /**
   * Navigate to the previous slide.
   *
   * If `slideBy` is `0`, the previous slide is the last partially obscured slide.
   * If `infinite` is `true`, the previous slide is the last slide if on the first
   * slide, otherwise it is the previous slide.
   * If `infinite` is `false`, the previous slide is the first slide if on the first
   * slide, otherwise it is the previous slide.
   *
   * @returns {Promise<void>}
   */

  prev() {
    const { childCount, state, props } = this
    const { activeIndex, slideBy } = state
    const { infinite } = props
    const firstIndex = 0
    const lastIndex = childCount - slideBy

    if (!slideBy) {
      const prevSlide = Math.max(activeIndex - 1, firstIndex)
      const prevInfinteSlide =
        prevSlide === activeIndex ? childCount - 1 : prevSlide
      return this.slideTo(infinite ? prevInfinteSlide : prevSlide)
    }

    const nextActive = Math.max(activeIndex - slideBy, firstIndex)
    const nextActiveInfinite =
      activeIndex === firstIndex ? lastIndex : nextActive
    return this.slideTo(infinite ? nextActiveInfinite : nextActive)
  }

  /**
   * Slide to a specific slide index.
   *
   * If `immediate` is `false` (default), the component will animate to the
   * specified slide index using the `easing` and `animationDuration` props.
   * If `infinite` is `true`, the component will wrap around to the other side
   * of the array if the specified slide index is out of range. If `infinite` is
   * `false`, the component will not wrap around and will instead clamp the
   * specified slide index to the range of the array.
   *
   * If `preventScroll` is `true`, the component will prevent the browser from
   * scrolling the element into view during the animation.
   *
   * The component will call the `beforeSlide` and `afterSlide` props if the
   * specified slide index is different from the current active index.
   *
   * @param {number} index The index to slide to.
   * @param {Object} [options={}] The options for the animation.
   * @param {boolean} [options.immediate=false] Whether to animate immediately.
   * @returns {Promise<void>}
   */
  slideTo(index, { immediate = false } = {}) {
    if (this.childCount === 0) {
      return Promise.reject('No children to slide to')
    }
    if (!this.whirligig) {
      return Promise.reject('The Whirligig is not mounted')
    }
    const {
      afterSlide,
      beforeSlide,
      easing,
      animationDuration: duration,
      infinite,
      preventScroll,
      snapPositionOffset,
    } = this.props
    const { children, scrollLeft } = this.whirligig
    const slideIndex = normalizeIndex(index, this.childCount, infinite)
    const startingIndex = this.state.activeIndex
    const delta =
      children[slideIndex].offsetLeft - scrollLeft - snapPositionOffset
    if (startingIndex !== slideIndex) {
      beforeSlide(index)
    }
    this.setState({ isAnimating: true, activeIndex: slideIndex })
    return new Promise((res, _) => {
      if (immediate) {
        this.whirligig.scrollLeft = children[slideIndex].offsetLeft
        return res()
      } else {
        const originalOverflowX = preventScroll ? 'hidden' : 'auto'
        const prop = 'scrollLeft'
        return res(
          animate(this.whirligig, {
            prop,
            delta,
            easing,
            duration,
            originalOverflowX,
          }),
        )
      }
    })
      .then(() => {
        this.setState({ isAnimating: false })
        if (startingIndex !== slideIndex) {
          return afterSlide(slideIndex)
        }
      })
      .catch(_ => {
        this.setState({ isAnimating: false })
      })
  }

  getNearestSlideIndex = () => {
    const { children, scrollLeft } = this.whirligig
    const offsets = [].slice
      .call(children)
      .map(({ offsetLeft }) => Math.abs(offsetLeft - scrollLeft))
    return offsets.indexOf(Math.min(...offsets))
  }

  setWhirligigRef = r => {
    this.whirligig = r
  }

  /**
   * Renders the Whirligig component.
   *
   * @param {Object} props - The props passed to the Whirligig component.
   * @returns {ReactElement} - The rendered Whirligig component.
   *
   * @param {Function} [props.children] - A function that accepts the `next` and `prev` functions and returns the content of the individual slides.
   * @param {string} [props.className] - The CSS classnames to apply to the container element.
   * @param {boolean} [props.preventScroll] - Whether to prevent the user from scrolling the component.
   * @param {number} [props.visibleSlides] - The number of visible slides.
   * @param {number} [props.height] - The height of the component.
   * @param {number} [props.width] - The width of the component.
   * @param {string} [props.slideClass] - The CSS classnames to apply to each slide.
   * @param {Function} [props.onSlideClick] - A function to call when a slide is clicked.
   */
  render() {
    const {
      afterSlide,
      animationDuration,
      beforeSlide,
      children,
      className,
      easing,
      infinite,
      gutter,
      nextKeys,
      prevKeys,
      preventScroll,
      preventAutoCorrect,
      preventSwipe,
      snapToSlide,
      snapPositionOffset,
      onSlideClick,
      slideClass,
      slideTo,
      slideBy,
      startAt,
      style,
      visibleSlides,
      height,
      width,
      ...props
    } = this.props

    const preventScrolling = preventScroll ? 'hidden' : 'auto'

    return (
      <div
        className={`flex flex-nowrap justify-start ${preventScrolling ? 'overflow-x-auto' : 'overflow-x-hidden'} relative transition-all duration-200 ease-in-out outline-none scrollbar-none creatorScrollbar`}
        ref={this.setWhirligigRef}
        tabIndex="0"
        onKeyUp={this.handleKeyUp}
        role="list"
        {...props}>
        {
          // We first pass the slide control functions to the function child.
          // This will return the `children` that will be the content of the individual slides.
          // Then we wrap the slide content in a slide component to add the functionality we need.
        }
        {React.Children.map(
          typeof children === 'function'
            ? children(this.next, this.prev)
            : children,
          (child, i) => (
            <Slide
              className={slideClass}
              key={`slide-${i}`}
              // basis={
              //   visibleSlides
              //     ? `calc((100% - (${gutter} * ${
              //         visibleSlides - 1
              //       })) / ${visibleSlides})`
              //     : 'auto'
              // }
              basis={`width: 210px`}
              gutter={i > 0 ? gutter : ''}
              onClick={onSlideClick}
              role="listitem">
              {child}
            </Slide>
          ),
        )}
        <div style={{ width: `${this.props.width}px`, height: `${this.props.height}px` }}></div>
      </div>
    )
  }
}
