/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

export const Config = {
  API_URL: '/api',
  WS_URL: '/socket',
  LIVEKIT_TOKEN: 'https://dev.api.livekit.beemyguest.jp',
  LIVEKIT_WSS: 'wss://dev.livekit.beemyguest.jp',
  LIVEKIT_URL: 'wss://dev.livekitlatest.beemyguest.jp',
};