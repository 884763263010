/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React, { useState } from "react";
import { useDidUpdateEffect } from "./use-did-update-effect";

import Tag from "./tags";

export interface TagsInputProps {
  name?: string;
  placeHolder?: string;
  value?: string[];
  onChange?: (tags: string[]) => void;
  error?: any;
  onBlur?: any;
  separators?: string[] | undefined;
  disableBackspaceRemove?: boolean;
  onExisting?: (tag: string) => void;
  onRemoved?: (tag: string) => void;
  disabled?: boolean;
  isEditOnRemove?: boolean;
  beforeAddValidate?: (tag: string, existingTags: string[]) => boolean;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  classNames?: {
    input?: string;
    tag?: string;
  };
}

const defaultSeparators = ["Enter"];

/**
 * A component that renders a text input with a list of tags.
 *
 * This component is designed to be used in a form. It will trigger the
 * `onChange` callback when the user types a new tag and presses the Enter key.
 * The `value` prop should be an array of strings.
 *
 * You can customize the appearance by passing a `classNames` object with
 * `input` and `tag` properties.
 *
 * The component also supports the following events:
 * - `onBlur`: Triggered when the user clicks outside the input field.
 * - `onKeyUp`: Triggered when the user presses a key while the input field is focused.
 * - `onRemoved`: Triggered when the user removes a tag.
 *
 * @prop {string} name The name of the input field.
 * @prop {string} placeHolder The placeholder text.
 * @prop {string[]} value The array of tags.
 * @prop {(tags: string[]) => void} onChange The callback triggered when the user types a new tag and presses the Enter key.
 * @prop {any} error The error message to display.
 * @prop {() => void} onBlur The callback triggered when the user clicks outside the input field.
 * @prop {string[]} separators The array of separator characters. Defaults to `['Enter', ',']`.
 * @prop {boolean} disableBackspaceRemove Whether to disable removing tags with the Backspace key. Defaults to `false`.
 * @prop {boolean} isEditOnRemove Whether to set the input field value to the last tag when the user removes a tag. Defaults to `false`.
 * @prop {(tag: string, existingTags: string[]) => boolean} beforeAddValidate The callback triggered before adding a new tag. It should return `true` if the tag should be added, or `false` otherwise.
 * @prop {(e: React.KeyboardEvent<HTMLInputElement>) => void} onKeyUp The callback triggered when the user presses a key while the input field is focused.
 * @prop {{ input?: string; tag?: string; }} classNames The object with CSS classes for the input field and the tag components.
 */
export const TagsInput = ({
  name,
  placeHolder,
  value,
  onChange,
  error,
  onBlur,
  separators = [],
  disableBackspaceRemove,
  onExisting,
  onRemoved,
  disabled,
  isEditOnRemove,
  beforeAddValidate,
  onKeyUp,
  classNames,
}: TagsInputProps) => {
  const [tags, setTags] = useState<string[]>(value || []);

  useDidUpdateEffect(() => {
    onChange && onChange(tags);
  }, [tags]);
  
  useDidUpdateEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(tags)) {
      setTags(value as string[]);
    }
  }, [value]);

  /**
   * A helper function to parse a string into an array of tags.
   * It will split the string by the given separators, trim the new tags,
   * filter out duplicate tags, and add the new tags to the existing tags.
   * @param {string} text The string to parse.
   * @returns {void}
   */
  const parseTextToTags = (text: string) => {
    if (!text) return;
  
    const separators = [',', ';', ' '];
    const newTags = text.split(new RegExp(separators.join('|'), 'g')).map(newTag => newTag.trim());
  
    const uniqueNewTags = Array.from(new Set(newTags));
  
    const newTagsToAdd = uniqueNewTags.filter(newTag => {
      if (!newTag) return false;
      if (tags.includes(newTag)) return false;
      if (beforeAddValidate && !beforeAddValidate(newTag, tags)) return false;
      return true;
    });
  
    setTags([...tags, ...newTagsToAdd]);
  };
  

  /**
   * Handles the key up event on the input field.
   * It will prevent the default behavior of the Enter key and add the new tag to the list.
   * It will also remove the last tag if the user presses the Backspace key.
   * @param {React.KeyboardEvent<HTMLInputElement>} e The event object.
   */
  const handleOnKeyUp = (e: any) => {
    e.stopPropagation();

    const text = e.target.value;

    // backspace remove
    if (
      !text &&
      !disableBackspaceRemove &&
      tags.length &&
      e.key === "Backspace"
    ) {
      e.target.value = isEditOnRemove ? `${tags.at(-1)} ` : "";
      setTags([...tags.slice(0, -1)]);
    }

    // add
    if (text && ([...separators, ...defaultSeparators]).includes(e.key)) {
      e.preventDefault();

      parseTextToTags(text);
      e.target.value = "";
    }
  };

  /**
   * Removes a tag from the list of tags.
   * It will remove the tag from the list of tags and call the `onRemoved` callback if provided.
   * @param {string} text The text of the tag to remove.
   */
  const onTagRemove = (text: string) => {
    setTags(tags.filter(tag => tag !== text));
    onRemoved && onRemoved(text);
  };

  const style= {
    border: tags.length>0 ?'1px solid #ccc':'',
    borderRadius: tags.length>0 ?'10px':'',
    padding: tags.length>0 ?'10px':'',
    margin:tags.length>0 ? '5px':'',
    display: tags.length>0 ?'block':'',
  }

  // TODO : need to remove excess invites
  
  return (
    <div
      aria-labelledby={name}
      className="chips w-96"
      style={style}
    >
      {tags.map((tag: string, index) => (
        <Tag
          key={tag}
          className={classNames?.tag}
          text={tag}
          remove={onTagRemove}
          error={error ? error[index] : null} 
          keyId={index} />
      ))}

      <input
        className={`bg-gray-200 border-2 border-black rounded-full leading-5 text-sm relative flex-1 w-full rounded-md p-input text-gray-400 md:text-base placeholder:text-sm placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent ring-1 ring-gray-300 border-solid border border-gray-300 h-8 ${disabled ? "bg-gray-200 border-none ring-0" : ""} border-none appearance-none`}
        type="text"
        autoFocus
        id={`inputEmail`}
        data-testid='inputEmail'
        name={name}
        placeholder={placeHolder}
        onKeyDown={handleOnKeyUp}
        onBlur={(e) => {
          parseTextToTags(e.target.value);  
          onBlur && onBlur(e);
          e.target.value = "";
        }}
        disabled={disabled}
        onKeyUp={onKeyUp}
       />
    </div>
  )
};
